<template>
  <div>
    <vs-row class="p-0">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"> 
            <vx-card class="p-0 mb-2">
                 <label class="vs-input--label">Cliente:</label>
                 <v-select  
                    label="nome"                                                                 
                    :options="listaClientes"  
                    :filter="pesquisarCliente"
                    v-model="clienteSelecionado"      
                    @input="fetchListaVeiculos"                                  
                    class="w-full vs-input--label">
                    <template v-slot:option="option">                        
                        <span style='font-family: Verdana, sans-serif;'> {{ option.CNPJCPF }} </span> - {{ option.nome }}  - {{ option.razaoSocial }}
                    </template>
                </v-select>  
            </vx-card>
        </vs-col>
    </vs-row> 
    <vs-row v-if="listaVeiculos.length">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"> 
            <vs-table :data="listaVeiculos" class="tabelaComScroll w-full mr-4" noDataText="..." search hoverFlat stripe multiple v-model="listaVeiculosSelecionados">
                <template slot="header">
                    <h6 class="ml-2 mt-2 mr-4 " v-if="listaVeiculos.length > 0 "> {{listaVeiculos.length}} veículos</h6>   
                    <vx-tooltip text="Imprimir formulários dos veículos selecionados.">                
                        <vs-button v-if="listaVeiculosSelecionados.length > 0" size="small" color="success" type="filled" icon-pack="feather" icon="icon-printer" @click="gerarRelatorio()"></vs-button>                 
                    </vx-tooltip>
                </template>
                <template slot="thead">
                    <vs-th class="p-0">Veículo</vs-th>                    
                    <vs-th class="p-0">Placa</vs-th>                    
                    <vs-th class="p-0">Código</vs-th>
                    <vs-th class="p-0">Ativação</vs-th>                    
                </template>
                <template slot-scope="{data}">
                    <vs-tr  :data="tr" :key="indextr" v-for="(tr, indextr) in data" style="border-bottom-style: solid; border-bottom-width: 1px; border-bottom-color: rgba(255, 255, 255, 0.2);">
                        <vs-td class="p-0"><small>{{tr.marca}} {{tr.modelo}} </small></vs-td>
                        <vs-td class="p-0"><small class="font-bold"> {{tr.placa}} </small></vs-td>
                        <vs-td class="p-0"><small class="font-bold">{{tr.codigoModulo}}</small> <small>{{tr.marcaModulo}}</small></vs-td>
                        <vs-td class="p-0"><small class="font-bold"> {{tr.dataAtivacao}} </small></vs-td>
                       
                    </vs-tr>
                </template>
            </vs-table>
        </vs-col>
    </vs-row>
    <vs-popup fullscreen title="Formulário Pedido" :active.sync="popUpImpressao.exibir" v-if="popUpImpressao.exibir">
        <ViewRelatorio v-if="dadosPDF" :sourcePDF="dadosPDF" class="mt-2"
            @exportarPDF="exportar('PDF')" @exportarXLS="exportar('XLS')" 
            @exportarTXT="exportar('TXT')" @exportarRTF="exportar('RTF')" />   
    </vs-popup>
  </div>
</template>

<script>
import axios from "@/axios.js"
import { CONSTANTE_TIPOS_CONTRATOS } from '@/constantesComboBox.js'
import ViewRelatorio from '@/views/components/ViewRelatorioJasper'

export default {   
    components: {      
        ViewRelatorio    
    },   
    created() {
      this.CONSTANTE_TIPOS_CONTRATOS = CONSTANTE_TIPOS_CONTRATOS;
    },
    data() {
        return {                        
            listaClientes: [], 
            listaVeiculos: [],            
            clienteSelecionado: null,                    
            errorsValidacao: [],   
            listaVeiculosSelecionados: [],   
            dadosPDF: null,              
            popUpImpressao: {
                exibir: false,          
            },
            popUpSelecao : {
                popupActive: false,
                exibirMensagem: false,
                mensagem: null          
            },
            publicPath: process.env.BASE_URL
        }
    },
    methods: {      
        fetchListaVeiculos() {     
            if (!this.clienteSelecionado || !this.clienteSelecionado.id) {
                this.listaVeiculos = null;
                this.listaVeiculos = [];
                return;
            }
            
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');
            params.append('pIdCliente', this.clienteSelecionado.id);
            params.append('cpfcnpj', this.clienteSelecionado.CNPJCPF);
            
            axios.post("/RelatorioCliente", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaVeiculos = response.data.listaVeiculos;  
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchListaVeiculos - " + error); 
            })    
                                
        },
       
        imprimir(tr) {
            this.listaVeiculosSelecionados = [];
            this.listaVeiculosSelecionados.push(tr);
            this.$vs.dialog({
                type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja imprimir o formulário de pedido do veículo '
                 + tr.placa + ' ? ', acceptText: 'Sim', cancelText: 'Não', accept: this.gerarRelatorio
            });
        },
       
        pesquisarCliente(options, varTermoPesquisa) {
            varTermoPesquisa = varTermoPesquisa.toUpperCase();
            return options.filter(function (c) {
                    return (c.nome.toUpperCase().indexOf(varTermoPesquisa) != -1  || c.razaoSocial.toUpperCase().indexOf(varTermoPesquisa) != -1 || 
                    c.CNPJCPF.toUpperCase().indexOf(varTermoPesquisa) != -1);
            })
        },        
        fetchListaClientes() {     
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('method', 'FindAll');
            params.append('orderDirection', 'ASC');
            params.append('outputFormat', 'JSON');
            params.append('naoExibirInativos', 'true');

            if (this.$store.state.AppActiveUser.perfil === "MONITOR_PANICO" || this.$store.state.AppActiveUser.perfil === "EMPRESA_MONITOR_PANICO") {
                params.append('monitoravelPorCentral', 'true');
            }
            
            axios.post("/ClientesRest", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaClientes = response.data;                             
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
            })    
                                
        },      
        exportar(pTipoFormato) {
            this.$vs.loading();

            var listIdVeiculos = null;

            for (var i= 0; i < this.listaVeiculosSelecionados.length; i++){
                if (listIdVeiculos === null) {
                    listIdVeiculos = new String();
                    listIdVeiculos = listIdVeiculos + this.listaVeiculosSelecionados[i].idVeiculo;
                } else {
                    listIdVeiculos = listIdVeiculos + "," + this.listaVeiculosSelecionados[i].idVeiculo;
                }
            }
            
            const params = new URLSearchParams();            
            params.append('outputFormat', 'JSON');          
            params.append('nomeRelatorio', "FormularioPedido");
            params.append('exportarTipo', pTipoFormato);
            params.append('pIdsVeiculos', listIdVeiculos);
            params.append('pIdCliente', this.clienteSelecionado.id);

            axios.post("/RelatoriosJasper", params, {              
                        credentials: 'include', responseEncoding: 'iso88591', withCredentials: true,  responseType: 'arraybuffer' } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {                
                    this.$router.push({ path: '/login' });
                } else {                                                
                    
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'FormulariosPedidos - ' +  this.clienteSelecionado.nome + '.' + pTipoFormato.toLowerCase());
                    document.body.appendChild(fileLink);
                    fileLink.click();
                }
            })
            .catch((error) => { 
                /* eslint-disable no-console */  console.log("EFETUAR LOGOFF! - exportar - " + error); 
                this.$vs.loading.close();
            }) 

        },
        gerarRelatorio() {
            this.$vs.loading();
            var listIdVeiculos = null;

            for (var i= 0; i < this.listaVeiculosSelecionados.length; i++){
                if (listIdVeiculos === null) {
                    listIdVeiculos = new String();
                    listIdVeiculos = listIdVeiculos + this.listaVeiculosSelecionados[i].idVeiculo;
                } else {
                    listIdVeiculos = listIdVeiculos + "," + this.listaVeiculosSelecionados[i].idVeiculo;
                }
            }

            const params = new URLSearchParams();            
            params.append('outputFormat', 'JSON');          
            params.append('nomeRelatorio', "FormularioPedido");
            params.append('exportarTipo', 'PDF');
            params.append('pIdsVeiculos', listIdVeiculos);
            params.append('pIdCliente', this.clienteSelecionado.id);

            axios.post("/RelatoriosJasper", params, {              
                        credentials: 'include', responseEncoding: 'iso88591', withCredentials: true,  responseType: 'arraybuffer' } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {                
                    this.$router.push({ path: '/login' });
                } else {                                                
                    this.dadosPDF = new Uint8Array(response.data);                
                    if (!this.dadosPDF || this.dadosPDF.length < 2000) {
                        this.dadosPDF = null;          
                        this.popUpImpressao.exibir = false;          
                    }  else {                  
                        this.popUpSelecao.popupActive = false;        
                        this.popUpImpressao.exibir = true;
                    }
                }
            })
            .catch((error) => { 
                /* eslint-disable no-console */  console.log("EFETUAR LOGOFF! - gravar - " + error); 
                this.$vs.loading.close();
            }) 
        },
    },
    mounted() {               
        this.fetchListaClientes();
    }
   
}
</script>

<style scoped>
  .tabelaComScroll {      
      overflow: auto;  
      height: calc(100vh - 200px);
      position: relative;      
    } 
</style>